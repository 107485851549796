/* eslint-disable @next/next/no-img-element */
import { FC, useMemo, useState, useEffect, useRef } from "react";
import type { GetServerSideProps } from "next";

import styles from "../styles/Home.module.scss";
import Button from "../components/UI/atoms/Button";
import {
  BusinessDetails,
  getBusinessDetails,
  getWebsiteLaunchScreen
} from "../api/home";
import { useRouter } from "next/router";
import ArrowIcon from "assets/svgs/icons/right-arrow.svg";
import LocationIcon from "assets/svgs/icons/location-2.svg";
import ErrorIcon from "assets/svgs/icons/error-info.svg";
import TypeaheadForm from "components/UI/molecules/TypeaheadForm";
import { useGlobalStore } from "context/GlobalContext";
import SelectForm from "components/UI/molecules/SelectForm";
import { confirmLocationRadius } from "api/location";
import BrandImage from "components/UI/molecules/BrandImage";
import AsyncTypeaheadComponent from "components/UI/organisms/AsyncTypeahead";
import { getSubdomain, isValidJSON } from "utils/utils";
import Checkbox from "components/UI/atoms/Checkbox";

interface Option {
  label?: string;
  value: string | number;
}

interface Business {
  brand_image: string;
  color: string;
  foreground: string;
  tagline: string;
  website_logo: string;
  delivery_areas: { name: string; uuid: string }[];
  business_name: string;
  branches: {
    uuid: string;
    name: string;
    gps?: boolean;
    delivery_areas: { name: string; uuid: string }[];
  }[];
  show_branches: boolean;
  pickup_enabled: boolean;
}

interface Props extends BusinessDetails {
  data: Business;
}

const Home: FC<Props> = ({ data, name, brand_images, vendor_id }) => {
  const router = useRouter();

  const {
    brand_image,
    tagline,
    color,
    delivery_areas,
    branches,
    show_branches,
    pickup_enabled
  } = data;

  const { setVendorId } = useGlobalStore();
  const [location, setLocation] = useState<string>("");
  const [errorText, setErrorText] = useState("");
  const [giftCard, setGiftCard] = useState("");
  const [singleSelections, setSingleSelections] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [orderMethod, setOrderMethod] = useState<"delivery" | "pickup">(
    "delivery"
  );
  const options = useMemo(
    () =>
      branches.map(({ name, uuid }) => ({
        label: name,
        value: uuid
      })),
    [branches]
  );

  useEffect(() => {
    setVendorId(vendor_id);
  }, [setVendorId, vendor_id]);

  useEffect(() => {
    if (router.query.giftcard) {
      setGiftCard(router.query.giftcard as string);
    } else {
      setGiftCard("");
    }
  }, [router.query]);

  const [branch, setBranch] = useState(branches[0]?.uuid);
  const [deliveryOptions, setDeliveryOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (show_branches) {
      if (branch) {
        const selectedBranch = branches.find((item) => item.uuid === branch);

        if (selectedBranch) {
          const areaOptions = selectedBranch.delivery_areas.map(
            ({ name, uuid }) => ({
              label: name,
              value: uuid
            })
          );

          setDeliveryOptions(areaOptions);
        }
      }
    } else {
      const areaOptions = delivery_areas.map(({ name, uuid }) => ({
        label: name,
        value: uuid
      }));
      setDeliveryOptions(areaOptions);
    }
  }, [branch, branches, show_branches, delivery_areas]);

  const branchHasGps = useMemo(() => {
    const singleBranch = branches.find((br) => br.uuid === branch);
    if (singleBranch && singleBranch.gps) return true;
    return false;
  }, [branch]);

  useEffect(() => {
    const address = localStorage.getItem("address");
    if (address && isValidJSON(address)) {
      setLocation(JSON.parse(address)[0].label);
    }
  }, []);

  return (
    <div className={styles.body}>
      <div className='container'>
        <div className={styles["giftcard-container"]}>
          <Button
            type='rounded'
            fullWidth
            className={styles.giftcard}
            onClick={() => router.push("/gift-card")}
            label={
              <div className={styles["giftcard-label"]}>
                <span>NEW</span>
                <div>
                  <p className={styles.desktop}>
                    Buy gift cards for you, your family and friends.
                  </p>
                  <p className={styles.mobile}>Get gift cards✨</p>

                  <ArrowIcon />
                </div>
              </div>
            }
          />
        </div>
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.header}>
              <h2>
                Welcome to <span className={styles.desktop}>{name}!</span>
              </h2>
              <h2 className={styles.mobile}>{name}!</h2>
              <p>{tagline}</p>
            </div>
            {errorText && (
              <div className={styles.error}>
                <div className='d-flex align-items-center'>
                  <ErrorIcon />
                </div>
                <p>{errorText}</p>
              </div>
            )}
            {pickup_enabled && (
              <div className={`${styles["order-method"]}`}>
                <p className={styles.label}>Select Method</p>
                <div className={styles.radio}>
                  <Checkbox
                    alt
                    checked={orderMethod === "delivery"}
                    onChange={() => {
                      setOrderMethod("delivery");
                    }}
                    label='Deliver to my address'
                  />

                  <Checkbox
                    alt
                    checked={orderMethod === "pickup"}
                    onChange={() => {
                      setOrderMethod("pickup");
                    }}
                    label='Pickup at the restaurant'
                  />
                </div>
              </div>
            )}

            {(show_branches || orderMethod === "pickup") && (
              <div
                className={`${styles.content} ${
                  orderMethod !== "pickup" && styles.branch
                }`}
              >
                <div className={styles["select-form"]}>
                  <SelectForm
                    id='branches'
                    label='Select Branch'
                    className={styles.select}
                    disabledOption={`Select ${name} branch`}
                    placeholder={`Select ${name} branch`}
                    value={branch || branches[0]?.uuid}
                    options={options}
                    onChange={(e) => {
                      setBranch(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            <div
              className={`${styles.content}  ${
                orderMethod === "pickup" && styles.pickup
              }`}
            >
              {orderMethod === "delivery" && (
                <div className={styles["select-form"]}>
                  {branchHasGps ? (
                    <AsyncTypeaheadComponent
                      label='Enter Delivery Address'
                      styles={styles.select}
                      setLocation={setLocation}
                      setErrorText={setErrorText}
                      errorText={errorText}
                    />
                  ) : (
                    <TypeaheadForm
                      label='Select Delivery Location'
                      placeholder='Select Delivery location'
                      disabledOption='Choose your location'
                      selectClass={styles.select}
                      id='location'
                      setSingleSelections={setSingleSelections}
                      singleSelections={singleSelections}
                      options={deliveryOptions}
                      value={location}
                      onSelected={(selected) => {
                        if (errorText.length) {
                          setErrorText("");
                        }
                        setLocation(selected?.value);
                      }}
                    />
                  )}
                </div>
              )}

              <div className={styles.cta}>
                <Button
                  onClick={async () => {
                    if (!location?.length && orderMethod === "delivery") {
                      setErrorText("Please choose a location");
                      return;
                    }

                    const query = {
                      ...(giftCard && { giftcard: giftCard })
                    };
                    if (branchHasGps && orderMethod === "delivery") {
                      try {
                        setLoading(true);
                        await confirmLocationRadius(branch, location);
                      } catch (error: any) {
                        const err = error?.response?.data?.message;
                        setErrorText(err);
                        setLoading(false);
                        return;
                      }

                      // @ts-ignore
                      query.vendor_branch_id = branch;
                    } else if (orderMethod === "delivery") {
                      // @ts-ignore
                      query.location = location;
                    } else {
                      // @ts-ignore
                      query.vendor_branch_id = branch;
                      // @ts-ignore
                      query.method = orderMethod;
                    }

                    // setLoading(false);
                    router.push({
                      pathname: "/meals",
                      query
                    });
                  }}
                  label={loading ? "Processing" : "Get Food"}
                  disabled={loading}
                  style={{ backgroundColor: color }}
                />
              </div>
            </div>
          </div>

          <BrandImage brand_images={brand_images!} brand_image={brand_image} />
        </div>
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const subdomain = getSubdomain(req.headers.host!);
  if (subdomain) {
    try {
      const { data } = await getWebsiteLaunchScreen(subdomain);
      const businessData = await getBusinessDetails(subdomain);
      return {
        props: {
          data,
          ...businessData
        }
      };
    } catch (error) {
      console.log(error);

      const err = error?.response?.data;

      if (err?.data?.deactivated) {
        return {
          redirect: {
            destination: `/404?message=${
              err?.data?.deactivated && "Store_not_available_at_the_moment"
            }`,
            permanent: false
          }
        };
      }
    }
  }

  return {
    notFound: true
  };
};
export default Home;
